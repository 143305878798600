import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { RentRequestsService } from 'src/app/services/rent-requests.service';
import {
  RENT_REQUEST_STATUS,
} from 'src/app/utils/constant';
import { Utils } from 'src/app/utils/utils';
import { PaymentService } from 'src/app/services/payment.service';
import { Router } from '@angular/router';
import { LoginService } from 'src/app/services/login.service';
import * as moment from 'moment';
import { MessageModalComponent } from '../../general/modal/message-modal/message-modal.component';
import { ConfirmModalComponent } from '../../matching/shared/confirm-modal/confirm-modal.component';
import { LayoutService } from 'src/app/services/layout.service';
import { MAX_SHORT_STAY_DAYS } from 'src/app/utils/constant';
import { RecurringPeriod } from 'src/app/models/general/recurringPeriod';
import { PayoutService } from 'src/app/services/payout.service';
import { MatSelectChange } from '@angular/material/select';
import { environment } from "../../../../environments/environment";

@Component({
  selector: 'app-reservation-detail',
  templateUrl: './reservation-detail.component.html',
  styleUrls: ['./reservation-detail.component.css'],
})
export class ReservationDetailComponent implements OnInit {
  reservation: any = null as any;
  activeId: number = 1;
  paymentActiveId: number = 1;
  roomsWithPrice: any[] = [];
  paymentHistoryStatus: string[] = [
    'Fully Paid',
    'Partically Paid',
    'Pending',
    'No-payment',
  ];
  selectedPaymentForCreateNewPaymentHistory: any = null as any;
  selectedPaymentIndex: number = 0;
  currentlyEditingIndex: number = 0; // for textarea onpened in a modal
  payoutPercentage: number = 0;
  saveSuccessfully: boolean = false;
  selectedDate: Date | null = null;
  selectedDateIndex: number = 0;
  @ViewChild('periodDropdown') periodDropdown: ElementRef = null as any;
  paymentStatusUser: any[] = [];
  isLongTerm: boolean = false;
  flagEditPrice: boolean = false;
  newPrice: string = '';
  isDateValidToChange: boolean = true;
  flagEditDate = false;
  newMoveInDate = undefined;
  newMoveOutDate = undefined;
  payoutPeriodSelectedIndex = 0;
  upcomingPayout = {};
  selectedUpcomingPayout = {};
  existingPayouts: any = [];


  amountAdjustment(stayInDays: number): number {
    const amount = 1;
    if (stayInDays > MAX_SHORT_STAY_DAYS && stayInDays <= 90) {
      // 2 - 3 months
      return amount * 0.75;
    } else if (stayInDays > 90 && stayInDays <= 120) {
      // 3 - 4 months
      return amount * 0.65;
    } else if (stayInDays > 120 && stayInDays <= 150) {
      // 4 - 5 months
      return amount * 0.6;
    } else if (stayInDays > 150 && stayInDays <= 180) {
      // 5 - 6 months
      return amount * 0.5;
    } else if (stayInDays > 180 && stayInDays <= 210) {
      // 6 - 7 months
      return amount * 0.4;
    } else if (stayInDays > 210 && stayInDays <= 240) {
      // 7 - 8 months
      return amount * 0.3;
    } else if (stayInDays > 240 && stayInDays <= 270) {
      // 8 - 9 months
      return amount * 0.2;
    } else if (stayInDays > 270 && stayInDays <= 300) {
      // 9 - 10 months
      return amount * 0.1;
    } else if (stayInDays > 300) {
      // 10+ months
      return 0;
    }
    return 1;
  }

  private updatePaymentHistoryStatus(index: number) {
    if (
      this.reservation.paymentHistory[index].status === RENT_REQUEST_STATUS.NO
    ) {
      this.reservation.paymentHistory[index].paymentStatus =
        this.paymentHistoryStatus[3];
    } else if (
      this.reservation.paymentHistory[index].status ===
      RENT_REQUEST_STATUS.PENDING
    ) {
      this.reservation.paymentHistory[index].paymentStatus =
        this.paymentHistoryStatus[2];
    } else if (
      this.reservation.paymentHistory[index].status ===
      RENT_REQUEST_STATUS.PARTIALLY_PAID
    ) {
      this.reservation.paymentHistory[index].paymentStatus =
        this.paymentHistoryStatus[1];
    } else if (
      this.reservation.paymentHistory[index].status === RENT_REQUEST_STATUS.PAID
    ) {
      this.reservation.paymentHistory[index].paymentStatus =
        this.paymentHistoryStatus[0];
    }
  }

  private initPaymentHistoryStatus() {
    for (let i = 0; i < this.reservation.paymentHistory.length; ++i) {
      this.updatePaymentHistoryStatus(i);
      this.reservation.paymentHistory[i].transactionFee = 3.5;
    }
  }

  constructor(
    private route: ActivatedRoute,
    private rentRequestsService: RentRequestsService,
    private utils: Utils,
    private modalService: NgbModal,
    private paymentService: PaymentService,
    private router: Router,
    private loginService: LoginService,
    public layoutService: LayoutService,
    private payoutService: PayoutService
  ) {}

  ngOnInit(): void {
    this.route.params.subscribe((params) => {
      this.rentRequestsService.getReservationById(params['id']).subscribe(
        (resp) => {
          this.reservation = resp;
          this.roomsWithPrice = this.getMonthlyRentRooms();
          this.initPaymentHistoryStatus();
          this.paymentStatusUser = this.getPaymentDetails();
          this.isLongTerm = this.utils.isLongTermStay(
            this.reservation.moveInDate,
            this.reservation.moveOutDate
          );
          this.loginService.setLoggedIn(true);
          this.payoutService.getPayoutsByReservationId(this.reservation._id, { statusExcept: RENT_REQUEST_STATUS.DELETED }).subscribe((payouts: any) => {
            this.existingPayouts = (payouts as any);
            this.existingPayouts.forEach((payout: any) => {
              payout.periodObj = new RecurringPeriod(payout.period.startDate, payout.period.endDate);
              if (this.isUpcomingPayout(payout)) {
                this.selectedUpcomingPayout = payout;
              }
            })
          })
        },
        (error) => {
          this.loginService.setLoggedIn(false);
          this.router.navigate(['/login']);
        }
      );
    });
    
  }

  getImgHost() {
    return this.utils.getImageHost();
  }

  isUpComing(): boolean {
    return new Date(this.reservation.moveInDate) > new Date();
  }

  isCompleted(): boolean {
    return new Date(this.reservation.moveOutDate) < new Date();
  }

  isActive(): boolean {
    const now = new Date();
    return (
      new Date(this.reservation.moveInDate) < now &&
      new Date(this.reservation.moveOutDate) > now
    );
  }

  isCanceledByAdmin(): boolean {
    return this.reservation.status === RENT_REQUEST_STATUS.ADMIN_CANCELLED;
  }

  getPurposeOfStay(): string {
    if (
      this.reservation.purposeOfStay &&
      this.reservation.purposeOfStay.length > 0
    )
      return this.reservation.purposeOfStay;
    return '/';
  }

  getMonthlyRentRooms() {
    let res = [];
    for (let roomId of this.reservation.roomIds) {
      for (let i = 0; i < this.reservation.apartment.rooms.length; ++i) {
        if (roomId == this.reservation.apartment.rooms[i]._id) {
          res.push({
            index: i,
            price: this.reservation.apartment.rooms[i].price,
          });
        }
      }
    }
    return res;
  }

  isSamePrice(): boolean {
    if (this.roomsWithPrice.length == 0) return false;
    let price = this.roomsWithPrice[0];
    let res = true;
    for (let i = 0; i < this.roomsWithPrice.length && res; ++i) {
      res = res && this.roomsWithPrice[i].price == price;
    }
    return res;
  }

  openModal(content: any, size: string) {
    this.modalService.open(content, { scrollable: true, size: size });
  }

  calculateTotalPaid() {
    return this.rentRequestsService.calculateTotalPaid(this.reservation);
  }

  getPaymentDetails() {
    let res = [];
    if (
      this.utils.isLongTermStay(
        this.reservation.moveInDate,
        this.reservation.moveOutDate
      )
    ) {
      for (let i = 0; i < this.reservation.paymentStatusUser.length; ++i) {
        res.push({
          paymentDate: this.reservation.paymentStatusUser[i].timestamp,
          monthlyRent: this.reservation.paymentStatusUser[i].transactionId
            ? this.reservation.paymentStatusUser[i].transactionId.amount
            : '_',
          status: this.reservation.paymentStatusUser[i].status,
          paidOn: this.reservation.paymentStatusUser[i].transactionId
            ? this.reservation.paymentStatusUser[i].transactionId.createdAt
            : '_',
        });
      }
    } else {
      res.push({
        paymentDate: this.reservation.transactionId.createdAt,
        monthlyRent: this.reservation.transactionId.amount,
        status: 'PAID',
        paidOn: this.reservation.transactionId.createdAt,
      });
    }
    return res;
  }

  changePaymentHistoryStatus(index: number, status: string) {
    if (status === this.paymentHistoryStatus[0]) {
      this.reservation.paymentHistory[index].status = RENT_REQUEST_STATUS.PAID;
    } else if (status === this.paymentHistoryStatus[1]) {
      this.reservation.paymentHistory[index].status =
        RENT_REQUEST_STATUS.PARTIALLY_PAID;
    } else if (status === this.paymentHistoryStatus[2]) {
      this.reservation.paymentHistory[index].status =
        RENT_REQUEST_STATUS.PENDING;
    } else if (status === this.paymentHistoryStatus[3]) {
      this.reservation.paymentHistory[index].status = RENT_REQUEST_STATUS.NO;
    }
    this.updatePaymentHistoryStatus(index);
  }

  updatePaymentHistory() {
    for (let payment of this.reservation.paymentHistory) {
      this.paymentService
        .paymentPaidToHost({
          _id: this.reservation._id,
          year: payment.year,
          month: payment.month,
          status: payment.status,
          amount: payment.amount,
          paymentDue: payment.paymentDue,
          actualPaid: payment.actualPaid,
          note: payment.note,
          createDate: payment.createDate,
          upcomingPayout: this.upcomingPayout,
        })
        .subscribe(
          (res) => {
            this.loginService.setLoggedIn(true);
            this.saveSuccessfully = true;
            setTimeout(() => {
              this.saveSuccessfully = false;
            }, 10000);
          },
          (error) => {
            this.loginService.setLoggedIn(false);
            this.router.navigate(['/login']);
          }
        );
    }
  }

  calculatePaymentDue(
    amount: number,
    transactionFeePercentage: number,
    payoutPercentage: number
  ): number {
    return (
      amount -
      (transactionFeePercentage / 100) * amount -
      ((100 - payoutPercentage) / 100) * amount
    );
  }

  getUserPaidStatus(): any[] {
    let res = [];
    if (
      this.isLongTermStay(
        this.reservation.moveInDate,
        this.reservation.moveOutDate
      )
    ) {
      for (let paymentStatus of this.reservation.paymentStatusUser) {
        if (paymentStatus.transactionId) res.push(paymentStatus);
      }
    } else {
      res.push({
        timestamp: new Date(this.reservation.transactionId.createdAt),
        amount: this.reservation.transactionId.amount,
      });
    }
    if (res.length > 0) {
      this.selectedDate = res[0].timestamp;
    }
    return res;
  }

  createDate(date: string | Date) {
    if (typeof date === 'object') {
      date = date.toISOString();
    }
    const dateParts = date.split('T')[0].split('-');
    const res = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2])
    );
    return res;
  }

  getEndDayOfFirstPayment(invoiceDateOfFirstPayment: any): any {
    const res = moment(this.createDate(invoiceDateOfFirstPayment))
      .add(1, 'month')
      .endOf('month')
      .startOf('day')
      .toDate();
    return res;
  }

  isLongTermStay(moveInDate: Date, moveOutDate: Date): boolean {
    return this.utils.isLongTermStay(moveInDate, moveOutDate);
  }

  selectPaymentDate(status: any) {
    this.selectedDate = status.timestamp;
  }

  moveInAtFirstDayOfTheMonth(): boolean {
    return moment(this.createDate(this.reservation.moveInDate)).date() === 1;
  }

  isLastPayment(paymentDate: any): boolean {
    return (
      this.createDate(paymentDate).getMonth() ===
      this.createDate(this.reservation.moveOutDate).getMonth()
    );
  }

  getPoiUrl(): string {
    return `${this.utils.getSecureDocUrl()}proofs-of-income/${
      this.reservation.poi
    }`;
  }

  getIdentityUrl(): string {
    return `${this.utils.getSecureDocUrl()}identities/${
      this.reservation.identity
    }`;
  }

  getOtherDocumentUrl(): string {
    return `${this.utils.getSecureDocUrl()}others/${
      this.reservation.otherDocument
    }`;
  }

  constructUrl(
    category: 'poi' | 'identity' | 'pos' | 'poe' | 'otherDocument' | 'agreement',
    item: any
  ): string {
    const entryDict: {
      [key: string]: string;
    } = {
      poi: 'proofs-of-income',
      identity: 'identities',
      pos: 'proof-of-study',
      poe: 'proof-of-employment',
      otherDocument: 'others',
      agreement: 'agreement',
    };
    const filename = typeof item === 'string' ? item : item.filename;
    return `${this.utils.getSecureDocUrl()}${entryDict[category]}/${filename}`;
  }

  updateReservationPrice() {
    const modalRef = this.modalService.open(ConfirmModalComponent);
    modalRef.closed.subscribe(() => {
      const compRef = this.modalService.open(MessageModalComponent);
      this.rentRequestsService
        .changeReservationPrice(this.reservation._id, parseFloat(this.newPrice))
        .subscribe(
          (resp) => {
            this.flagEditPrice = false;
            this.reservation.originalRent = parseFloat(this.newPrice);
            this.reservation.agreementDocument = undefined;
            compRef.componentInstance.title = 'Success';
            compRef.componentInstance.message = 'Successful operation';
          },
          ({ error }) => {
            compRef.componentInstance.title = 'Failed';
            if (!error.error) error = { error: error };
            compRef.componentInstance.message = error.error;
            this.flagEditPrice = false;
          }
        );
    });
  }

  changeMoveInDate(date: any): void {
    this.reservation.newMoveInDate = date;
  }

  changeMoveOutDate(date: any): void {
    this.reservation.newMoveOutDate = date;
  }

  updateReservationDate() {
    const ref = this.modalService.open(ConfirmModalComponent);
    ref.closed.subscribe(() => {
      const modalRef = this.modalService.open(MessageModalComponent);
      this.rentRequestsService
        .changeReservationDate(
          this.reservation._id,
          this.reservation.newMoveInDate,
          this.reservation.newMoveOutDate
        )
        .subscribe(
          (resp) => {
            this.flagEditDate = false;
            this.reservation.moveInDate =
              this.reservation.newMoveInDate || this.reservation.moveInDate;
            this.reservation.moveOutDate =
              this.reservation.newMoveOutDate || this.reservation.moveOutDate;
            this.reservation.agreementDocument = undefined;

            this.generateAgreementPDF();

            modalRef.componentInstance.title = 'Success';
            modalRef.componentInstance.message = 'Successful operation';
          },
          ({ error }) => {
            modalRef.componentInstance.title = 'Fail';
            if (!error.error) error = { error: error };
            modalRef.componentInstance.message = error.error;
            this.flagEditDate = false;
          }
        );
    });
  }

  cancelReservation() {
    const shouldCancel = window.confirm('Are you sure you want to cancel?');

    if (shouldCancel) {
      this.rentRequestsService
        .cancelReservation(this.reservation._id)
        .subscribe(
          (resp) => {
            this.reservation.status = RENT_REQUEST_STATUS.ADMIN_CANCELLED;
          },
          ({ error }) => {
            alert(error.error);
          }
        );
    }
  }

  sendAgreementToHost() {
    this.rentRequestsService
      .sendRentalAgreementToGuest(this.reservation._id)
      .subscribe(
        (resp) => {
          alert('Agreement Sent to Guest');
        },
        ({ error }) => {
          alert(error.error);
        }
      );
  }

  generateAgreementPDF() {
    this.rentRequestsService
      .generateAgreementPDF(this.reservation._id)
      .subscribe(
        (resp) => {
          this.reservation.agreementDocument =
            resp.reservation.agreementDocument;
        },
        ({ error }) => {
          alert(error.error);
        }
      );
  }

  stringifyPayout(payout: any): string {
    return new RecurringPeriod(payout.period.startDate, payout.period.endDate).format();
  }

  isUpcomingPayout(payout: any): boolean {
    return 'isUpcoming' in payout && payout.isUpcoming;
  }

  handleUpcomingPayoutSelectChange(event: MatSelectChange) {
    const payout = event.value;
    this.selectedUpcomingPayout = payout;
    this.reservation.upcomingPayment = {
      year: moment.utc(payout.period.startDate).year(),
      month: moment.utc(payout.period.startDate).month() + 1,
      amount: payout.amountDue
    };
  }

  getSubscriptionOrPaymentUrl() {
    return this.isLongTerm ? `${environment.stripeUrl}/subscriptions/${this.reservation.subscriptionId}` : `${environment.stripeUrl}/charges/${this.reservation.transactionId.id}`
  }

}

